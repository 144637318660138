'<template>
	<div class="aboutContainer">
		<div class="banner"></div>
		<div class="content_container">
			<div class="font1">加入我们</div>
			<div class="font2"><span class="font2-text">JOIN US</span><span class="splitLine"></span></div>
			<div class="joinUsContainer">
				<ul style="margin-bottom: 2rem;">
					<router-link v-for="item in articalList" :key="item.id" :to="{path:'/joinDetail',query: {articleId:item.id}}"><li  class="joinItem">{{item.aTitle}}<span>——发布时间:{{item.createtime}}</span></li></router-link>
				</ul>
				
				<div class="pageContainer">
					<paginate :page-count="Math.ceil(pageSplitInfo.total/pageSplitInfo.pageSize)" :page-range="2" :margin-pages="2"  :click-handler="clickCallback" :prev-text="'上一页'" :next-text="'下一页'" :container-class="'pagination'" :page-class="'page-item'">
					</paginate>
				</div>
			</div>
			<div style="clear:both;"></div>
		</div>
	</div>
</template>

<script>
	import Paginate from 'vuejs-paginate'
	import {
		getArticles
	} from '@/api/request'
	import {
		Base64
	} from 'js-base64'
	export default {
		name: 'news',
		components: {
			Paginate
		},
		data() {
			return {
				articalList: [],
				currentContent: "",
				pageSplitInfo: {
					currentPage: 1,
					pageSize: 10,
					sortName:"CREATETIME",
					sortString:"desc",
					total: 200,
					topicCode: "joinus"
				}
			}
		},
		computed: {
	
		},
		mounted() {
	
		},
		created() {
			this.feathData()
		},
		methods: {
			clickCallback(pageNum) {
				this.pageSplitInfo.currentPage = pageNum;
				this.feathData();
			},
			formartDate(dateStr){
				let datetime = new Date(dateStr);
				return datetime.getFullYear();
			},
			formartDate2(dateStr){
				let datetime = new Date(dateStr);
				let month = datetime.getMonth()+1;
				month = month<10?"0"+month:month;
				let date = datetime.getDate();
				date = date<10?"0"+date:date;
				return month+"-"+date;
			},
			feathData(){
				getArticles(this.pageSplitInfo).then(resp=>{
					if(resp.data.code=="200"){
						let respData = resp.data.result;
						this.articalList = respData.data;
						this.pageSplitInfo.total = respData.total;
					}
				})
			},
			linkToDetail(item){
				console.log(item);
			}
		}
	}
</script>

<style>
	.aboutContainer {
		min-height: 800px;
		background-color: #175068;
	}

	.banner {
		width: 100%;
		height: 10rem;
		background: url(../../public/images/introductionbanner.png) no-repeat;
		background-size: 100% 100%;
	}
	.joinUsContainer .joinItem{
		list-style: none;
		line-height: 3rem;
		height:3rem;
		color:#FFFFFF;
		border-bottom: 1px dashed #557D8E;
		font-size: 0.8rem;
		cursor: pointer;
		transition: all 0.5s; 
	}
	.joinUsContainer .joinItem:hover{
		color: #2CAFB6;
		border-color: #2CAFB6;
	}
	.joinUsContainer .joinItem span{
		display: block;
		font-size:0.6rem; color:#999;
		height:3rem;
		line-height:4rem;
		float:right;
	}
	.pageContainer{
		width:100%;
		position: relative;
	}
	.pagination {
		display: block;
		position: absolute;
		left:50%;
		transform:translateX(-50%) ;
	
	}
	
	.pagination li {
		list-style: none;
		min-width: 1.5rem;
		height: 1.5rem;
		float: left;
		text-align: center;
		margin-right: 0.2rem;
		background-color: #00727A;
		color: #FFFFFF;
		border: 1px solid #FFFFFF;
		transition: all 0.5s; 
	}
	
	
	.pagination .active {
		color: #FFFFFF;
		background-color: #1B98A3;
	}
	
	.pagination .disabled {
		color: #999999;
		border-color: #999999;
		
	}
	
	.pagination a {
		font-size: 0.6rem;
		line-height: 1.5rem;
		outline: none;
		display: block;
		width: 100%;
		height: 100%;
		-webkit-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
	}
	
	.pagination li:first-child a,
	.pagination li:last-child a {
	
		padding-left: 1rem;
		padding-right: 1rem;
	}
</style>
